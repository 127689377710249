import React, { useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { getPublicImagePath } from '@nutastic/nutastic-react-app';

const MusicPlayer = () => {
    const componentClass = 'musicPlayer';
    const [currentTrack, setTrackIndex] = useState(0);

    const playlist = [
        { name: "Feelings You Can't Touch", src: getPublicImagePath('gelato/Feelings.mp3'), cover: getPublicImagePath('gelato/GD--small.png') },
        { name: 'Bentley Grill Cheese', src: getPublicImagePath('singles/BGC.mp3'), cover: getPublicImagePath('gelato/GD--small.png') },
        { name: 'A Little More Poetic Justice', src: getPublicImagePath('gelato/Poetic.mp3'), cover: getPublicImagePath('gelato/GD--small.png') },
        { name: 'Plug On Hold', src: getPublicImagePath('gelato/Plug.mp3'), cover: getPublicImagePath('gelato/GD--small.png') },
        { name: 'Doing 2 Much (ft. Sauce Walka)', src: getPublicImagePath('d2m/D2M.mp3'), cover: getPublicImagePath('d2m/d2m--512.png') },
        { name: 'Favors For Mayors', src: getPublicImagePath('basketball/music/2--mayor.mp3'), cover: getPublicImagePath('basketball/sizes/mrball--512.png') },
        { name: 'Gatorade Player Of The Year', src: getPublicImagePath('basketball/music/9--gatorade.mp3'), cover: getPublicImagePath('basketball/sizes/mrball--512.png') },
        { name: '45', src: getPublicImagePath('summerFunUsa/music/6--45.mp3'), cover: getPublicImagePath('summerFunUsa/covers/6.png') },
        { name: 'London, Milan', src: getPublicImagePath('summerFunUsa/music/7--london.mp3'), cover: getPublicImagePath('summerFunUsa/covers/7.png') },
        { name: 'Maintaining Without Complaining', src: getPublicImagePath('singles/maintaining.mp3'), cover: getPublicImagePath('singles/AllBarsCover--Small.png') }
    ];

    useEffect(() => {
        if ('mediaSession' in navigator) {
            navigator.mediaSession.metadata = new MediaMetadata({
                title: playlist[currentTrack].name,
                artist: 'Quistar',
                album: 'QuistarGo.com',
                artwork: [
                    { src: playlist[currentTrack].cover, sizes: '512x512', type: 'image/png' }
                ]
            });
        }
    }, [currentTrack]);

    const handleClickPrevious = () => {
        setTrackIndex(currentTrack => currentTrack > 0 ? currentTrack - 1 : 0);
    };

    const handleClickNext = () => {
        setTrackIndex(currentTrack => currentTrack < playlist.length - 1 ? currentTrack + 1 : 0);
    };

    const handleEnd = () => {
        setTrackIndex(currentTrack => currentTrack < playlist.length - 1 ? currentTrack + 1 : 0);
    };

    return <div className={componentClass}>
        <AudioPlayer
            volume="0.5"
            autoPlay={false}
            src={ playlist[currentTrack].src }
            showJumpControls={false}
            showSkipControls={true}
            showDownloadProgress={false}
            loop={false}
            layout={'horizontal-reverse'}
            customVolumeControls={[]}
            customAdditionalControls={[]}
            showFilledVolume={false}
            onClickNext={handleClickNext}
            onClickPrevious={handleClickPrevious}
            onEnded={handleEnd}
        />
    </div>;
};

export default MusicPlayer;
