/* eslint-disable */
import React from 'react';
import {getPublicImagePath} from '@nutastic/nutastic-react-app';
import PageSlider from '../PageSlider';
import {ReactSVG} from 'react-svg';
import MusicPlayer from "../MusicPlayer";

const ComingSoonPage = () => {
    const componentClass = 'coming-soon-page';
    //const { xl : isLargeScreen } = useBreakpoints();
    const slides = [
        getPublicImagePath('gelato/GDQuis.jpg'),
        getPublicImagePath('singles/BentleyGrillCheese.jpg'),
        getPublicImagePath('gelato/PoeticJustice.jpg'),
        getPublicImagePath('gelato/GDPlane.jpg'),
        getPublicImagePath('d2m/Doing2Much--Cover1.jpg'),
        getPublicImagePath('basketball/covers/main.jpg'),
        getPublicImagePath('basketball/covers/list.jpg'),
        getPublicImagePath('summerFunUsa/summerFun--45.jpg'),
        getPublicImagePath('summerFunUsa/summerFun.jpg'),
        getPublicImagePath('singles/AllBarsCover.jpg')
    ];

    return <React.Fragment>
        <header className={`${componentClass}__header`}>
            <ReactSVG src={ getPublicImagePath('araytv-logo.svg') } />
        </header>
        <PageSlider slides={slides} />
        <div className="labelIcons">
            <a href="https://music.apple.com/us/artist/quistar/495597228" target="_blank"><img src={getPublicImagePath('label--apple.png')} alt="Quistar on Apple Music" /></a>
            <a href="https://open.spotify.com/artist/3ovosgASk8TNvin2GWru2M" target="_blank"><img src={getPublicImagePath('label--spotify.png')} alt="Quistar on Spotify" /></a>
            <a href="https://youtu.be/t0MLI3tUX18?si=3Dgdip0M1sNOnBbo" target="_blank"><img src={getPublicImagePath('label--youtube.png')} alt="Quistar on Youtube" /></a>
        </div>
        <MusicPlayer />
    </React.Fragment>
};

export default ComingSoonPage;
