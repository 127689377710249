import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/swiper-react.cjs';
import SwiperCore, { Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import PropTypes from 'prop-types';
import GlitchClip from '../refactor/react-glitch-effect/GlitchClip';

SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);
const componentClass = 'pageSlider';
const swiperProps = {
    autoplay: { delay: 25000 },
    loop: true,
    slidesPerView: 1,
    direction: 'vertical',
    effect: 'slide',
    pagination: {
        className: `${componentClass}__pagination`,
        clickable: true
    }
};

const PageSlider = ({ slides }) => {

    return <Swiper className={componentClass} { ...swiperProps }>
        { slides.map((slide, i) => {
            return <SwiperSlide key={i}>
                <div className={`${componentClass}__page`}>
                    <GlitchClip duration={3000} className="glitch-image">
                        <img src={ slide } alt={`The New A-RayTV Coming Soon | 2023 | (${i})`} />
                    </GlitchClip>
                </div>
            </SwiperSlide>;
        })}
    </Swiper>;
};
PageSlider.propTypes = {
    slides: PropTypes.string.isRequired
};

export default PageSlider;
